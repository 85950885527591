import React, { useEffect, useState } from "react";
import { Flex, Box, Badge, Text } from "@chakra-ui/react";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

import SEO from "../components/Seo";
import Nav from "../components/Nav";
import ReusableHero from "../components/ReusableHero";
import ReusableFooter from "../components/ReusableFooter";
import StaticLogo from "../components/StaticLogo";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import MobileNav from "../components/MobileNav";
import Slideshow from "../components/Slideshow";

import chevron from "../images/chevron.svg";
import casestudies from "../images/features/casestudies.png";

import sd from "../images/scraped/sodelishus.json";
import ox from "../images/scraped/oxted_festival.json";
import man from "../images/scraped/mane_uk.json";
import hello from "../images/scraped/hello_vans.json";
import house from "../images/scraped/house_of_strengh.json";
import sub from "../images/scraped/subfrantic-music.json";
import boss from "../images/scraped/bossy_bitch_maid.json";
import capital from "../images/scraped/capital_clue_hunts.json";
import suss from "../images/scraped/sussex_removals.json";
import sch from "../images/scraped/sch_tech.json";
import city from "../images/scraped/city_doggo.json";
import pro from "../images/scraped/proclean.json";
import bee from "../images/scraped/beemoved.json";
import sly from "../images/scraped/slynote.json";
import aren from "../images/scraped/aren_heating_plumbing.json";
import exp from "../images/scraped/expert_apps.json";
// import lbp from '../images/scraped/life_balance.json';
// import virt from '../images/scraped/virtlx.json';
import bw from "../images/scraped/black_and_white_london.json";
import bod from "../images/scraped/bodiesmade_online.json";
import tailor from "../images/scraped/sussex_tailored.json";
import reader from "../images/scraped/readers_school.json";
// import intune from '../images/scraped/intune_consulting.json';
// import terrilyn from '../images/scraped/terri_lynn.json';
// import online from '../images/scraped/online_fitness_freedom.json';
import reign from "../images/scraped/reign_supreme.json";
import gm from "../images/scraped/geoffrey_matthew.json";
import gs from "../images/scraped/grant_space.json";
import hg from "../images/scraped/honest_grapes.json";
import pv from "../images/scraped/villa.json";
import rh from "../images/scraped/racehorse.json";
import rock from "../images/scraped/rockit.json";
import up from "../images/scraped/upseeks.json";

import l47 from "../images/newone/47.svg";
import l2 from "../images/newone/2.svg";
import l40 from "../images/newone/40.svg";
import l45 from "../images/newone/45.svg";
import l7 from "../images/newone/7.svg";
import l37 from "../images/newone/37.svg";
import l38 from "../images/newone/38.svg";
import l44 from "../images/newone/44.svg";
import l55 from "../images/newone/55.svg";
import l14 from "../images/newone/14.svg";
import l4 from "../images/newone/4.svg";
import l50 from "../images/newone/50.svg";
import l53 from "../images/newone/53.svg";
import l34 from "../images/newone/34.svg";
import l52 from "../images/newone/52.svg";
import l3 from "../images/newone/3.svg";
import l15 from "../images/newone/15.svg";
import l17 from "../images/newone/17.svg";
import l12 from "../images/newone/12.svg";
import l19 from "../images/newone/19.svg";

import "../styles/casestudies.css";
import ViewMoreButton from "../components/ViewMoreButton";
import AccomplishmentSlider from "../components/AccomplishmentSlider";

function ClientView({ num, setActiveImg, setOpen }) {
  const [views, setViews] = useState([]);

  const viewsarray = [
    {
      link: "sodelishus.co.uk",
      subheader: "Sodelishus is a health-focused food company",
      type: "FOOD COMPANY",
      logo: l47,
      img1: "https://ascendgroupmedia.com/images/delishus/0.png",
      img2: "https://ascendgroupmedia.com/images/delishus/1.png",
      img3: "https://ascendgroupmedia.com/images/delishus/2.png",
      img4: "https://ascendgroupmedia.com/images/delishus/3.png",
      page: "/case_studies/sodelishus",
      colorScheme: "gray",
    },
    {
      link: "oxtedfest.co.uk",
      subheader: "Music Festival based in Oxted",
      type: "CONCERT BOOKING",
      logo: l2,
      img1: "https://ascendgroupmedia.com/images/oxted/0.png",
      img2: "https://ascendgroupmedia.com/images/oxted/1.png",
      img3: "https://ascendgroupmedia.com/images/oxted/2.png",
      img4: "https://ascendgroupmedia.com/images/oxted/3.png",
      page: "/case_studies/oxtedfest",
      colorScheme: "green.300",
    },
    {
      link: "honestgrapes.co.uk",
      subheader: "Indy Best Online Wine Shop",
      type: "ONLINE WINE SHOP",
      logo: l40,
      img1: "https://ascendgroupmedia.com/images/honestgrapes/1.jpg",
      img2: "https://ascendgroupmedia.com/images/honestgrapes/2.jpg",
      img3: "https://ascendgroupmedia.com/images/honestgrapes/3.jpg",
      img4: "https://ascendgroupmedia.com/images/honestgrapes/4.jpg",
      page: "/case_studies/honest_grapes",
    },
    {
      link: "racehorseclub.co.uk",
      subheader: "",
      logo: l52,
      type: "",
      img1: "https://ascendgroupmedia.com/images/RacehorseClub/1.jpg",
      img2: "https://ascendgroupmedia.com/images/RacehorseClub/2.jpg",
      page: "/case_studies/racehorse_club",
    },

    // done

    {
      link: "readerschool.org",
      subheader: "A full interactive and fun e-learning platform for children.",
      type: "Online Children Learning platform",
      logo: l50,
      img1: "https://ascendgroupmedia.com/images/Readers/1.jpg",
      img2: "https://ascendgroupmedia.com/images/Readers/2.jpg",
      img3: "https://ascendgroupmedia.com/images/Readers/3.jpg",
      img4: "https://ascendgroupmedia.com/images/Readers/4.jpg",
      colorScheme: "cyan.300",
      page: "/case_studies/readers_school",
    },

    // done
    {
      link: "maneuk.com",
      subheader: "Hair Thickening Solutions Company",
      type: "HAIR PRODUCTS",
      logo: l45,
      img1: "https://ascendgroupmedia.com/images/Mane/0.png",
      img2: "https://ascendgroupmedia.com/images/Mane/1.png",
      img3: "https://ascendgroupmedia.com/images/Mane/2.png",
      img4: "https://ascendgroupmedia.com/images/Mane/3.png",
      page: "/case_studies/maneuk",
      colorScheme: "yellow.300",
    },
    // done

    {
      link: "hellovans.com",
      subheader: "Online Van removals comparison company",
      type: "vAN COMPANY",
      logo: l7,
      img1: "https://ascendgroupmedia.com/images/Vans/0.png",
      img2: "https://ascendgroupmedia.com/images/Vans/1.png",
      img3: "https://ascendgroupmedia.com/images/Vans/2.png",
      img4: "https://ascendgroupmedia.com/images/Vans/3.png",
      page: "/case_studies/hellovans",
      colorScheme: "blue.300",
    },
    // done

    {
      link: "houseofstrength.co.uk",
      subheader:
        "A leading uk manufacturer and distributor of weighted plates and olympic bars",
      type: "GYM EQUIPMENTS",
      logo: l37,
      img1: "https://ascendgroupmedia.com/images/House/0.png",
      img2: "https://ascendgroupmedia.com/images/House/1.png",
      img3: "https://ascendgroupmedia.com/images/House/2.png",
      img4: "https://ascendgroupmedia.com/images/House/3.png",
      page: "/case_studies/house_of_strength",
      colorScheme: "teal.300",
    },
    // done

    {
      link: "grantspace.co.uk",
      subheader: "Make funding go far",
      type: "Funding",
      logo: l38,
      img1: "https://ascendgroupmedia.com/images/Space/1.jpg",
      img2: "https://ascendgroupmedia.com/images/Space/2.jpg",
      img3: "https://ascendgroupmedia.com/images/Space/3.jpg",
      img4: "https://ascendgroupmedia.com/images/Space/4.jpg",
      page: "/case_studies/grant_space",
    },
    // done

    {
      link: "subfranticmusic.com",
      subheader: "The number one online live streaming service for musicians",
      type: "Online music streaming",
      logo: l44,
      img1: "https://ascendgroupmedia.com/images/frantic/1.png",
      img2: "https://ascendgroupmedia.com/images/frantic/2.jpg",
      img3: "https://ascendgroupmedia.com/images/frantic/3.jpg",
      img4: "https://ascendgroupmedia.com/images/frantic/4.jpg",
      page: "/case_studies/subfrantic_music",
      colorScheme: "orange.300",
    },
    // done

    {
      link: "geoffreymatthew.co.uk",
      subheader: "Estate Agency at its Best",
      type: "Estate Agency",
      img1: "https://ascendgroupmedia.com/images/Geoffrey/1.jpg",
      img2: "https://ascendgroupmedia.com/images/Geoffrey/2.jpg",
      img3: "https://ascendgroupmedia.com/images/Geoffrey/3.jpg",
      img4: "https://ascendgroupmedia.com/images/Geoffrey/4.jpg",
      page: "/case_studies/geoffrey_matthew",
    },
    // done

    {
      link: "bossybitchmaid.me.uk",
      subheader: "England's most unique maid and butler service",
      type: "Bossy Bitch Maids",
      img1: "https://ascendgroupmedia.com/images/BBM/1.jpg",
      img2: "https://ascendgroupmedia.com/images/BBM/2.jpg",
      img3: "https://ascendgroupmedia.com/images/BBM/3.jpg",
      img4: "https://ascendgroupmedia.com/images/BBM/4.jpg",
      colorScheme: "red.300",
      page: "/case_studies/bossy_bitch_maid",
    },

    // done

    {
      link: "prestigevillageandvillas.co",
      subheader: "Estate Agency at its Finest",
      type: "Estate Agency",
      logo: l55,
      img1: "https://ascendgroupmedia.com/images/Prestige/1.jpg",
      img2: "https://ascendgroupmedia.com/images/Prestige/2.jpg",
      img3: "https://ascendgroupmedia.com/images/Prestige/3.jpg",
      img4: "https://ascendgroupmedia.com/images/Prestige/4.jpg",
      page: "/case_studies/prestige_villas",
    },
    // done

    {
      link: "capitalcluehunts.com",
      subheader:
        "Real world adventure games in London. All you need is a phone, a team and a sharp mind for the cryptic",
      type: "Game Company",
      img1: "https://ascendgroupmedia.com/images/capital/1.jpg",
      img2: "https://ascendgroupmedia.com/images/capital/2.jpg",
      img3: "https://ascendgroupmedia.com/images/capital/3.jpg",
      img4: "https://ascendgroupmedia.com/images/capital/4.jpg",
      colorScheme: "blue.300",
      page: "/case_studies/capital_clue_hunts",
    },
    // done

    {
      link: "sussexremovals.co.uk",
      subheader:
        "Sussex Tailored Removals is a friendly and Professional Removals and Storage In Sussex.",
      type: "Removal Company",
      logo: l14,
      img1: "https://ascendgroupmedia.com/images/susex/removals-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/susex/removals-cs3.jpg",
      img3: "https://ascendgroupmedia.com/images/susex/removals-cs4.jpg",
      img4: "https://ascendgroupmedia.com/images/susex/removals-cs5.jpg",
      colorScheme: "green.300",
      page: "/case_studies/sussex_removals",
    },
    // done

    {
      link: "schtech.co.uk",
      subheader: "We Listen, Create & Deliver YOUR Technology Solutions.",
      type: "Technology Company",
      logo: l19,
      img1: "https://ascendgroupmedia.com/images/SCH/sch-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/SCH/sch-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/SCH/sch-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/SCH/sch-cs4.jpg",
      colorScheme: "blue.300",
      page: "/case_studies/sch_tech",
    },
    // done

    {
      link: "citydoggo.com",
      subheader:
        "City Doggo introduces a return to nature for your metropolitan pet.",
      type: "Pet Products Company",
      logo: l12,
      img1: "https://ascendgroupmedia.com/images/doggo/1.jpg",
      img2: "https://ascendgroupmedia.com/images/doggo/2.jpg",
      img3: "https://ascendgroupmedia.com/images/doggo/3.jpg",
      img4: "https://ascendgroupmedia.com/images/doggo/4.jpg",
      colorScheme: "green.300",
      page: "/case_studies/city_doggo",
    },
    // done

    {
      link: "procleanpartnership.co.uk",
      subheader: "We Listen, Create & Deliver YOUR Technology Solutions.",
      type: "Luxury cleaning service.",
      logo: l17,
      img1: "https://ascendgroupmedia.com/images/proclean/proclean-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/proclean/proclean-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/proclean/proclean-cs5.jpg",
      img4: "https://ascendgroupmedia.com/images/proclean/proclean-cs6.jpg",
      colorScheme: "gray.300",
      page: "/case_studies/proclean",
    },
    // done

    {
      link: "beemoved.com",
      subheader: "Trusted Brighton removals & storage company.",
      type: "Transport Company",
      img1: "https://ascendgroupmedia.com/images/Beemoved/beemoved1.jpg",
      img2: "https://ascendgroupmedia.com/images/Beemoved/beemoved2.jpg",
      img3: "https://ascendgroupmedia.com/images/Beemoved/beemoved3.jpg",
      img4: "https://ascendgroupmedia.com/images/Beemoved/beemoved5.jpg",
      colorScheme: "cyan.300",
      page: "/case_studies/bee_moved",
    },
    // done

    {
      link: "slynote.com",
      subheader: "Slynote makes sending encrypted data simple.",
      type: "Encrypted notes Company",
      logo: l15,
      img1: "https://ascendgroupmedia.com/images/Slynote/slynote-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/Slynote/slynote-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/Slynote/slynote-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/Slynote/slynote-cs4.jpg",
      colorScheme: "teal.300",
      page: "/case_studies/slynote",
    },

    // done

    {
      link: "arenheatingplumbing.co.uk",
      subheader: "Plumbing and heating company based in London",
      type: "Plumbing and heating company",
      logo: l3,
      img1: "https://ascendgroupmedia.com/images/Plumbing/0.png",
      img2: "https://ascendgroupmedia.com/images/Plumbing/1.png",
      img3: "https://ascendgroupmedia.com/images/Plumbing/2.png",
      img4: "https://ascendgroupmedia.com/images/Plumbing/3.png",
      colorScheme: "red.300",
      page: "/case_studies/aren_heating",
    },
    // done

    {
      link: "expertapps.online",
      subheader: "App Development Platform for Business",
      type: "Encrypted notes Company",
      img1: "https://ascendgroupmedia.com/images/ExpertApps/apps-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/ExpertApps/apps-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/ExpertApps/apps-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/ExpertApps/apps-cs4.jpg",
      colorScheme: "cyan.300",
      page: "/case_studies/expert_apps",
    },
    // done

    {
      link: "upseeks",
      subheader: "Get Swiping to match with your ideal worker or business",
      type: "Job Matching",
      img1: "https://ascendgroupmedia.com/images/Upseeks/1.jpg",
      img2: "https://ascendgroupmedia.com/images/Upseeks/2.jpg",
      img3: "https://ascendgroupmedia.com/images/Upseeks/3.jpg",
      img4: "https://ascendgroupmedia.com/images/Upseeks/4.jpg",
      page: "/case_studies/upseeks",
    },

    // done
    {
      link: "bodiesmadeonline.com",
      subheader:
        "You’ll Taken The First Steps To Transforming Your Body & Life.",
      type: "Fitness Services",
      img1: "https://ascendgroupmedia.com/images/bossybitchmaid/bmo-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/bossybitchmaid/bmo-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/bossybitchmaid/bmo-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/bossybitchmaid/bmo-cs4.jpg",
      colorScheme: "teal.300",
      page: "/case_studies/bodiesmade_online",
    },
    // done

    // {
    //   link: "rockithair",
    //   logo: l53,
    //   subheader: "",
    //   type: "",
    //   img1: "https://ascendgroupmedia.com/images/RockItHair/1.jpg",
    //   page: "/case_studies/rock_it_hair",
    // },
    // done

    {
      link: "tailoredremoval.co.uk",
      subheader: "Removals and storage company based in Sussex",
      type: "Tailored Removal Company",
      logo: "",
      img1: "https://ascendgroupmedia.com/images/TailoredRemovalGroup/trg-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/TailoredRemovalGroup/trg-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/TailoredRemovalGroup/trg-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/TailoredRemovalGroup/trg-cs4.jpg",
      colorScheme: "yellow.300",
      page: "/case_studies/sussex_tailored",
    },
    // done

    {
      link: "reignsupremeuk.com",
      subheader:
        "Accountant In Kent, Who Speaks To You More Than Just Once A Year",
      type: "Business & Accounting Company",
      logo: l4,
      img1: "https://ascendgroupmedia.com/images/Supreme/supreme-cs1.jpg",
      img2: "https://ascendgroupmedia.com/images/Supreme/supreme-cs2.jpg",
      img3: "https://ascendgroupmedia.com/images/Supreme/supreme-cs3.jpg",
      img4: "https://ascendgroupmedia.com/images/Supreme/supreme-cs4.jpg",
      colorScheme: "teal.300",
      page: "/case_studies/reign_supreme",
    },
    // done
  ];

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      let newarr = [];

      for (let i = 0; i < num; i++) {
        newarr.push(viewsarray[i]);
      }

      setViews(newarr);
    }

    return () => {
      mounted = false;
    };
  }, [num]);

  return (
    <Box w="90%">
      {views.map((client, i) => {
        return (
          <Box
            key={i}
            w="100%"
            className="caseStudyBox"
            my="35px"
            px="10px"
            key={client.link}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w="100%"
              mt="30px"
              flexWrap="wrap"
            >
              <Box>
                {client.logo ? (
                  <img src={client.logo} alt="client logo" className="logocs" />
                ) : null}
              </Box>
              <Box className="viewshead" p="10px">
                <Text as="h3">
                  <a href={`https://${client?.link}/`} target="_blank">
                    {client?.link}
                  </a>
                </Text>

                <Text
                  as="h5"
                  fontStyle="dm-sans"
                  fontSize="md"
                  textAlign="center"
                >
                  {client?.subheader}
                </Text>
              </Box>
            </Flex>
            <Flex mt="30px" justifyContent="center">
              <Flex
                w="100%"
                justifyContent="center"
                alignItems="center"
                px={["10px", "10px", "10px", "50px", "50px"]}
                borderRadius="12px"
                flexWrap="wrap"
              >
                <Flex flexWrap="wrap" justifyContent="center">
                  <Flex
                    borderRadius="70px"
                    justifyContent="center"
                    mx="5px"
                    w={["100%", "100%", "100%", "33%", "33%"]}
                    my="5px"
                  >
                    <img
                      src={client?.img1}
                      alt="client_showcase"
                      className="imgholder"
                      onClick={() => {
                        setActiveImg(client?.img1);
                        setOpen(true);
                      }}
                      SameSite="None"
                    />
                  </Flex>
                  <Flex
                    w={["100%", "100%", "100%", "33%", "33%"]}
                    borderRadius="70px"
                    justifyContent="center"
                    mx="5px"
                    my="5px"
                  >
                    {client.img2 ? (
                      <img
                        src={client?.img2}
                        alt="client_showcase"
                        className="imgholder"
                        onClick={() => {
                          setActiveImg(client?.img2);
                          setOpen(true);
                        }}
                        SameSite="None"
                      />
                    ) : null}
                  </Flex>
                </Flex>
                <Flex
                  w={["100%", "100%", "100%", "33%", "33%"]}
                  borderRadius="70px"
                  justifyContent="center"
                  mx="5px"
                  my="5px"
                >
                  {client.img3 ? (
                    <img
                      src={client?.img3}
                      alt="client_showcase"
                      className="imgholder"
                      onClick={() => {
                        setActiveImg(client?.img3);
                        setOpen(true);
                      }}
                      SameSite="None"
                    />
                  ) : null}
                </Flex>
                <Flex
                  w={["100%", "100%", "100%", "33%", "33%"]}
                  borderRadius="70px"
                  justifyContent="center"
                  mx="5px"
                  my="5px"
                >
                  {client?.img4 ? (
                    <img
                      src={client.img4}
                      alt="client_showcase"
                      className="imgholder"
                      onClick={() => {
                        setActiveImg(client.img4);
                        setOpen(true);
                      }}
                      SameSite="None"
                    />
                  ) : null}
                </Flex>
              </Flex>
            </Flex>
            <Flex
              w="100%"
              justifyContent="center"
              alignItems="center"
              my="50px"
            >
              <Box>
                <ViewMoreButton link={client?.page} />
              </Box>
            </Flex>
          </Box>
        );
      })}
    </Box>
  );
}

export default function Case_studies({ location }) {
  const [activeQty, setActiveQty] = useState(5);

  const [Open, setOpen] = useState(false);
  const [activeImg, setActiveImg] = useState("");

  const headerText = "CASE";
  const subheader = "STUDIES";
  const paragraph =
    "Below, you'll find a variety of clients and brands we have had the pleasure of working with.";
  const text =
    "Do you have a project in mind? Get An Estimate for your Digital Marketing Campaign";

  const isBrowser = typeof window !== "undefined";

  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const updateDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    if (isBrowser) {
      updateDimensions();
      window.addEventListener("resize", updateDimensions);

      if (window.scrollY !== 0) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }

    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <Box className="main" overflowX="hidden">
      <SEO />
      {width > 1280 ? (
        <Nav loc={location} page="/case_studies" />
      ) : (
        <MobileNav page="/case_studies" />
      )}
      <Box>
        <ReusableHero
          header={headerText}
          subheader={subheader}
          paragraph={paragraph}
          image={casestudies}
          separated={false}
          imageClass="digitalmarkbanner"
        />
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mt="100px"
        >
          <Box>
            <h3 className="casefeatures">
              CASE <span>STUDIES</span>
            </h3>
          </Box>
          <Box px="5%" pt="20px">
            <p className="casesmaller">
              Here, you'll find case studies of a select amount of brands we
              have partnered with. Ascend Media has helped them all scale
              significantly through tailored marketing strategies and ongoing
              management. Partner with us for the same level of quality, effort
              and personalized strategies to help scale your business.
            </p>
          </Box>
        </Flex>
        {/* <Flex
          mt="50px"
          mx="20px"
          h="100px"
          justifyContent="flex-end"
        >
          <Text
            fontSize="sm"
            mr="10px"
            className="view"
          >
            View
          </Text>
          <button
            className={
              (activeQty === 5) 
              ? "activeselector"
              : "qtyselector"
            }
            onClick={() => {
              setActiveQty(5)
            }}
          >
            5
          </button>
          <button
            className={
            (activeQty === 10) 
            ? "activeselector"
            : "qtyselector"
            }
            onClick={() => {
              setActiveQty(10)
            }}
          >
            10
          </button>
          <button
            className={
              (activeQty === 15) 
              ? "activeselector"
              : "qtyselector"
            }
            onClick={() => {
              setActiveQty(15)
            }}
          >
            15
          </button>
          <button
            className={
              (activeQty === 20) 
              ? "activeselector"
              : "qtyselector"
            }
            onClick={() => {
              setActiveQty(20)
            }}
          >
            20
          </button>
          <button
            className={
              (activeQty === 26) 
              ? "activeselector"
              : "qtyselector"
            }
            onClick={() => {
              setActiveQty(26)
            }}
          >
            All
          </button>
        </Flex> */}
        <Flex justifyContent="center" className="clientContainer">
          <ClientView
            num={activeQty}
            setActiveImg={setActiveImg}
            setOpen={setOpen}
          />
        </Flex>
        <Flex w="100%" justifyContent="center" my="100px">
          {activeQty < 28 ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              onClick={() => {
                setActiveQty((num) => {
                  if (activeQty === 25) {
                    return 28;
                  }

                  return num + 5;
                });
              }}
            >
              <img src={chevron} alt="chevron" className="chevronseemore" />
              <Text
                as="h3"
                mt="50px"
                fontSize={["20px", "20px", "30px", "30px", "30px"]}
                fontFamily="dm-sans"
                color="#0A2F53"
              >
                View More
              </Text>
            </Flex>
          ) : null}
        </Flex>
        <AccomplishmentSlider />
        <Slideshow />
        {/* <StaticLogo />     */}
        <ReusableFooter text={text} />
        <Footer />
      </Box>
      {Open ? (
        <Lightbox
          image={activeImg}
          onClose={() => setOpen((prevState) => !prevState)}
        />
      ) : null}
    </Box>
  );
}

export { ClientView };
